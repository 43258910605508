import companiesApi from '@/api/companies-api'
import i18n from '@/libs/i18n'

const state = {
  currentCompany: null,
  showModalCompanies: false,
  listCompanies: [],
}

const mutations = {
  recoverCurrentCompany(state) {
    const recoveredCurrentCompany = JSON.parse(localStorage.getItem('currentCompany'))
    if (!recoveredCurrentCompany) {
      state.currentCompany = null
    } else {
      state.currentCompany = recoveredCurrentCompany
    }
  },
  setShowModalCompanies(state, val) {
    state.showModalCompanies = val
  },
  setlistCompanies(state, val) {
    state.listCompanies = val
  },
  setCurrentCompany(state, val) {
    state.currentCompany = val
    localStorage.setItem('currentCompany', JSON.stringify(state.currentCompany))
    this.commit('clients/removeCurrentClient')
    this.commit('clients/setShowModalClients', true)
  },
  removeCurrentCompany(state) {
    state.currentCompany = null
    localStorage.removeItem('currentCompany')
  },
}

const actions = {
  async searchCompanies({ commit }, search = null) {
    commit('app/loading', true, { root: true })
    await companiesApi.list(search)
      .then(
        response => {
          commit('setlistCompanies', response.data.data)
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorListado', { nombre: i18n.t('Compañias') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getCurrentCompany: state => state.currentCompany,
  getListCompanies: state => state.listCompanies,
  getShowModalCompanies: state => state.showModalCompanies,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
