import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/companies'

export default {
  list(search = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      search,
    })
  },
}
