import Vue from 'vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import axios from 'axios'
import store from '../store'
import i18n from '@/libs/i18n'

const lang = store.getters['languages/getCurrentLanguage']
const { messages } = require(`vee-validate/dist/locale/${lang}.json`)

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  })
})
extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: i18n.t('Las contraseñas no coinciden'),
});

extend('web', {
  params: ['target'],
  validate(value) {
    const regex = RegExp('(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*(\\?[;&a-z\\d%_.~+=-@]*)?(\\#[-a-z\\d_@]*)?$', 'i');
    return value.match(regex)
  },
  message: i18n.t('Web no valida'),
})

const emailUnique = (value, args) => {
  return axios.post(`${process.env.VUE_APP_ROOT_API  }/users/existemail`, { email : value, id: args.target})
    .then((response) => {
      return true
    })
    .catch(error => {
      return i18n.t('Debe ser un email único')
    })
}
extend('emailUnique', {
  params: ['target'],
  validate: emailUnique,
})

const codeClientUnique = (value, args) => {
  return axios.post(`${process.env.VUE_APP_ROOT_API}/clients/existcode`, { code : value, id: args.target})
    .then((response) => {
      return true
    })
    .catch(error => {
      return i18n.t('Debe ser un código único')
    })
}
extend('codeClientUnique', {
  params: ['target'],
  validate: codeClientUnique,
})
