import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/departments'

export default {
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  edit(id, department) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, department)
  },
  create(department) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, department)
  },
  list(idClient = '', search = '', perPage, page) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      id_client: idClient,
      search,
      per_page: perPage,
      page,
    })
  },
  getDepartment(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
}
