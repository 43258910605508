import projectsApi from '../../api/projects-api'
import generalApi from '@/api/general-api'
import router from '@/router'

const state = {
  project: {},
  items: [],
  selectProjects: [],
  selectStatus: [],
  totalItems: 0,
}

const mutations = {
  setProject(state, val) {
    state.project = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setSelectStatus(state, val) {
    state.selectStatus = val
  },
  setSelectProjects(state, val) {
    state.selectProjects = val
  },
}

const actions = {
  async selectStatus({ commit }) {
    await generalApi.status('project')
      .then(
        response => {
          commit('setSelectStatus', response.data)
        },
        () => {
          commit('notifications', { title: 'Error en la carga de estados.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getProject({ commit }, id) {
    commit('app/loading', true, { root: true })
    await projectsApi.getProject(id)
      .then(
        response => {
          commit('setProject', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el proyecto.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getListProjects({ commit }, {
    client = '', page, per_page, search = '',
  }) {
    commit('app/loading', true, { root: true })
    projectsApi.getList(client, page, per_page, search)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el listado de proyectos.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getSelectProjects({ commit }, {
    client = '',
    search = '',
  }) {
    await projectsApi.getList(client, 1, 9999999, search)
      .then(
        response => {
          commit('setSelectProjects', response.data.data)
        },
        () => {
          commit('notifications', { title: 'Error al obtener el listado de proyectos.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, project }) {
    commit('app/loading', true, { root: true })
    projectsApi.edit(id, project)
      .then(
        () => {
          commit('notifications', { title: 'Proyecto actualizado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar proyecto.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { project }) {
    commit('app/loading', true, { root: true })
    projectsApi.create(project)
      .then(
        response => {
          commit('notifications', { title: 'Proyecto creado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          router.push({ name: 'editProject', params: { id: response.data.data.id } })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear proyecto.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    projectsApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: 'Proyecto eliminado con éxito.', variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar el proyecto.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getProject: state => state.project,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getSelectStatus: state => { return state.selectStatus },
  getSelectProjects: state => { return state.selectProjects },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
