import setsApi from '../../api/sets-api'
import router from '@/router'
import i18n from '@/libs/i18n'

const state = {
  set: {},
  items: [],
  selectSets: [],
  totalItems: 0,
}

const mutations = {
  setSet(state, val) {
    state.set = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setSelectStatus(state, val) {
    state.selectStatus = val
  },
  setSelectSets(state, val) {
    state.selectAssets = val
  },
}

const actions = {
  async getSet({ commit }, id) {
    commit('app/loading', true, { root: true })
    await setsApi.getSet(id)
      .then(
        response => {
          commit('setSet', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorGet', { nombre: i18n.t('Agrupacion') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getListSets({ commit }, {
    client = '', page, per_page, search = '',
  }) {
    commit('app/loading', true, { root: true })
    setsApi.getList(client, page, per_page, search)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorListado', { nombre: i18n.t('Agrupaciones') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getSelectSets({ commit }, {
    client = '',
  }) {
    await setsApi.getList(client, 1, 9999999, '')
      .then(
        response => {
          commit('setSelectSets', response.data.data)
        },
        () => {
          commit('notifications', { title: i18n.t('ErrorListado', { nombre: i18n.t('Agrupaciones') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, set }) {
    commit('app/loading', true, { root: true })
    setsApi.edit(id, set)
      .then(
        () => {
          commit('notifications', { title: i18n.t('ErrorUpdate', { nombre: i18n.t('Agrupacion') }), variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('SuccessUpdate', { nombre: i18n.t('Agrupacion') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    setsApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: i18n.t('SuccessDelete', { nombre: i18n.t('Agrupacion') }), variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorDelete', { nombre: i18n.t('Agrupacion') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { set }) {
    commit('app/loading', true, { root: true })
    setsApi.create(set)
      .then(
        response => {
          commit('notifications', { title: i18n.t('SuccessCreate', { nombre: i18n.t('Agrupacion') }), variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          router.push({ name: 'editSet', params: { id: response.data.data.id } })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorCreate', { nombre: i18n.t('Agrupacion') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getSet: state => state.set,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getSelectSets: state => { return state.selectSets },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
