import axios from "axios";
import { config } from "../shared/app.config";

const RESOURCE_NAME = "/users";

export default {
  edit(id, user) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, user);
  },
  create(user) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, user);
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`);
  },
  getUser(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`);
  },
  contact_person(user) {
    return axios.post(
      `${config.webApiBase + RESOURCE_NAME}/contact_person`,
      user
    );
  },
  list(
    company = "",
    client = "",
    subclient = "",
    page,
    per_page,
    search,
    roles = []
  ) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      id_company: company,
      id_client: client,
      id_subclient: subclient,
      page,
      per_page,
      search,
      roles,
    });
  },
  resetPassword(id, oldPassword, newPassword) {
    return axios.put(
      `${config.webApiBase + RESOURCE_NAME}/${id}/password-change`,
      {
        oldPassword,
        newPassword,
      }
    );
  },
  app_access(id) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/app_access/${id}`);
  },
};
