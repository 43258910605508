export default {
  resolveAndDownloadBlob(dataBlob, filename, extension = null) {
    let fullname = extension ? `${filename}.${extension}` : filename
    fullname = decodeURI(fullname)
    const url = window.URL.createObjectURL(dataBlob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fullname)
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url)
    link.remove()
  },
}
