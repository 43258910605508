import router from '@/router'
import i18n from '@/libs/i18n'
import locationsApi from '../../api/locations-api'

const state = {
  location: {},
  items: [],
  selectLocations: [],
  totalItems: 0,
}

const mutations = {
  setLocation(state, val) {
    state.location = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setSelectLocations(state, val) {
    state.selectLocations = val
  },
}

const actions = {
  async getLocation({ commit }, id) {
    commit('app/loading', true, { root: true })
    await locationsApi.getLocation(id)
      .then(
        response => {
          commit('setLocation', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorGet', { nombre: i18n.t('Localizacion') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getListLocations({ commit }, {
    clients = [], page, per_page, search = '',
  }) {
    commit('app/loading', true, { root: true })
    await locationsApi.getList(clients, page, per_page, search)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorListado', { nombre: i18n.t('Localizaciones') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getSelectLocations({ commit }, {
    clients = [],
  }) {
    await locationsApi.getList(clients, 1, 9999999, '')
      .then(
        response => {
          commit('setSelectLocations', response.data.data)
        },
        () => {
          commit('notifications', { title: i18n.t('ErrorListado', { nombre: i18n.t('Localizaciones') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, location }) {
    commit('app/loading', true, { root: true })
    locationsApi.edit(id, location)
      .then(
        () => {
          commit('notifications', { title: i18n.t('SuccessUpdate', { nombre: i18n.t('Localizacion') }), variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorUpdate', { nombre: i18n.t('Localizacion') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    locationsApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: i18n.t('SuccessDelete', { nombre: i18n.t('Localizacion') }), variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorDelete', { nombre: i18n.t('Localizacion') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { location }) {
    commit('app/loading', true, { root: true })
    locationsApi.create(location)
      .then(
        () => {
          commit('notifications', { title: i18n.t('SuccessCreate', { nombre: i18n.t('Localizacion') }), variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          router.push({ name: 'locations' })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorCreate', { nombre: i18n.t('Localizacion') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  principal({ commit }, id) {
    locationsApi.principal(id)
      .then(
        () => {
          commit('notifications', { title: 'Localización pricipal asignada.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
        () => {
          commit('notifications', { title: 'Error al asignar la localización principal.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getLocation: state => state.location,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getSelectLocations: state => state.selectLocations,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
