import subClientsApi from "@/api/subclients-api";
import router from "@/router";
import i18n from "@/libs/i18n";

const state = {
  subclient: {},
  items: [],
  totalItems: 0,
  selectSubClients: [],
};

const mutations = {
  setSubClient(state, val) {
    state.subclient = val;
  },
  setItems(state, val) {
    state.items = val;
  },
  setTotalItems(state, val) {
    state.totalItems = val;
  },
  setSelectSubClients(state, val) {
    state.selectSubClients = val;
  },
};

const actions = {
  //edit
  edit({ commit }, { id, user }) {
    commit("app/loading", true, { root: true });
    subClientsApi.edit(id, user).then(
      () => {
        console.log("guardando subcliente", id, user);
        commit(
          "notifications",
          {
            title: i18n.t("SuccessUpdateMaculino", {
              nombre: i18n.t("SubClient"),
            }),
            variant: "success",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
        commit("app/loading", false, { root: true });
      },
      (error) => {
        commit("app/loading", false, { root: true });

        commit(
          "notifications",
          {
            title: i18n.t("ErrorUpdate", { nombre: i18n.t("SubClient") }),
            variant: "danger",
          },
          { root: true }
        );

        commit("setShowNotifications", true, { root: true });
      }
    );
  },

  //getOne
  async getSubClient({ commit }, id) {
    commit("app/loading", true, { root: true });
    await subClientsApi.getSubClient(id).then(
      (response) => {
        commit("setSubClient", response.data.data);
        commit("app/loading", false, { root: true });
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          {
            title: i18n.t("ErrorGet", { nombre: i18n.t("SubClient") }),
            variant: "danger",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  //list
  getListSubClients(
    { commit },
    { client = "", page, per_page, search = "", roles = [] }
  ) {
    commit("app/loading", true, { root: true });

    subClientsApi.list(client, page, per_page, search, roles).then(
      (response) => {
        commit("setItems", response.data.data);
        commit("setTotalItems", response.data.meta.total[1]);
        commit("app/loading", false, { root: true });
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          {
            title: i18n.t("ErrorListado", { nombre: i18n.t("SubClientes") }),
            variant: "danger",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  //delete
  delete({ commit }, id) {
    commit("app/loading", true, { root: true });
    subClientsApi.delete(id).then(
      () => {
        commit(
          "notifications",
          {
            title: i18n.t("SuccessDeleteMasculino", {
              nombre: i18n.t("SubClient"),
            }),
            variant: "success",
          },
          { root: true }
        );
        location.reload();
      },
      (error) => {
        commit("app/loading", false, { root: true });
        commit("setShowNotifications", true, { root: true });
        if (
          error.response.data.error &&
          error.response.data.error === "Subclient have contact_persons"
        ) {
          commit(
            "notifications",
            {
              title: i18n.t("NoBorrarSubClient"),
              variant: "danger",
            },
            { root: true }
          );
        } else if (
          error.response.data.error &&
          error.response.data.error === "Subclient have installations"
        ) {
          commit(
            "notifications",
            {
              title: i18n.t("NoBorrarTieneInstalaciones"),
              variant: "danger",
            },
            { root: true }
          );
        } else {
          commit(
            "notifications",
            {
              title: i18n.t("ErrorDelete", { nombre: i18n.t("SubClient") }),
              variant: "danger",
            },
            { root: true }
          );
        }
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  //create
  create({ commit }, { user, redirect = true }) {
    commit("app/loading", true, { root: true });

    // Devolver la promesa de la llamada a la API para que se pueda esperar en el componente
    return subClientsApi.create(user).then(
      (response) => {
        commit(
          "notifications",
          {
            title: i18n.t("SuccessCreateMasculino", {
              nombre: i18n.t("SubClient"),
            }),
            variant: "success",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
        if (redirect) router.push({ name: "subclients" });
        commit("app/loading", false, { root: true });

        // Devolver la respuesta de la llamada a la API
        return response;
      },
      (error) => {
        commit("app/loading", false, { root: true });

        commit(
          "notifications",
          {
            title: i18n.t("ErrorCreate", { nombre: i18n.t("SubClient") }),
            variant: "danger",
          },
          { root: true }
        );

        commit("setShowNotifications", true, { root: true });

        // Rechazar la promesa con el error para que se pueda capturar en el componente
        return Promise.reject(error);
      }
    );
  },
};

const getters = {
  getSubClient: (state) => state.subclient,
  getItems: (state) => state.items,
  getTotalItems: (state) => state.totalItems,
  getSelectSubClients: (state) => state.selectSubClients,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
