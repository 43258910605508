import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/locations'

export default {
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  edit(id, location) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, location)
  },
  principal(id) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/principal/${id}`)
  },
  create(location) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, location)
  },
  getLocation(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  getList(clients = [], page, per_page, search) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      clients,
      page,
      per_page,
      search,
    })
  },
}
