import router from '@/router'
import generalApi from '@/api/general-api'
import i18n from '@/libs/i18n'
import episApi from '../../api/epis-api'

const state = {
  epi: {},
  items: [],
  selectStatus: [],
  selectEpis: [],
  totalItems: 0,
  summaryEpisClient: [],
}

const mutations = {
  setEpi(state, val) {
    state.epi = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setSelectStatus(state, val) {
    state.selectStatus = val
  },
  setSelectEpis(state, val) {
    state.selectEpis = val
  },
  setSummaryEpisClient(state, val) {
    state.summaryEpisClient = val
  },
}

const actions = {
  async selectStatus({ commit }) {
    await generalApi.status('epi').then(
      response => {
        commit('setSelectStatus', response.data)
      },
      () => {
        commit(
          'notifications',
          { title: i18n.t('ErrorListado', { nombre: i18n.t('Estados') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  async getEpi({ commit }, id) {
    commit('app/loading', true, { root: true })
    await episApi.getEpi(id).then(
      response => {
        if (response.data.data.archives) {
          if (response.data.data.archives.review_epi) {
            response.data.data.archives.review = response.data.data.archives.review_epi
          }
          if (response.data.data.archives.review_epi) {
            response.data.data.archives.review = response.data.data.archives.review_epi
          }
          if (response.data.data.archives.delivery_no_epi) {
            response.data.data.archives.delivery = response.data.data.archives.delivery_no_epi
          }
          if (response.data.data.archives.delivery_epi) {
            response.data.data.archives.delivery = response.data.data.archives.delivery_epi
          }
        }
        commit('setEpi', response.data.data)
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorGet', { nombre: i18n.t('Epi') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  getListEpis(
    { commit },
    {
      client = '',
      page,
      per_page,
      search = '',
      category_id = '',
      status = '',
      vida_util = '',
      prox_revision = '',
      product = '',
      user = '',
      date_ini,
      date_fin,
      location_id,
      num_albaran,
    },
  ) {
    commit('app/loading', true, { root: true })
    episApi
      .getList(
        client,
        page,
        per_page,
        search,
        category_id,
        status,
        vida_util,
        prox_revision,
        product,
        user,
        date_ini,
        date_fin,
        location_id,
        num_albaran,
      )
      .then(
        response => {
          response.data.data.forEach(element => {
            if (element.archives) {
              if (element.archives.review_epi) {
                element.archives.review = element.archives.review_epi
              }
              if (element.archives.review_epi) {
                element.archives.review = element.archives.review_epi
              }
              if (element.archives.delivery_no_epi) {
                element.archives.delivery = element.archives.delivery_no_epi
              }
              if (element.archives.delivery_epi) {
                element.archives.delivery = element.archives.delivery_epi
              }
            }
          })
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit(
            'notifications',
            {
              title: i18n.t('ErrorListado', { nombre: i18n.t('Epis') }),
              variant: 'danger',
            },
            { root: true },
          )
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getListSummary({ commit }, { product_id }) {
    commit('app/loading', true, { root: true })
    episApi.getListSummary(product_id).then(
      response => {
        commit('setSummaryEpisClient', response.data)
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          {
            title: i18n.t('ErrorCarga', { nombre: i18n.t('Dashboard') }),
            variant: 'danger',
          },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  async getSelectEpis({ commit }, { client = '' }) {
    await episApi.getList(client, 1, 9999999, '').then(
      response => {
        commit('setSelectEpis', response.data.data)
      },
      () => {
        commit(
          'notifications',
          {
            title: i18n.t('ErrorListado', { nombre: i18n.t('Epi') }),
            variant: 'danger',
          },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  edit({ commit }, { id, epi }) {
    commit('app/loading', true, { root: true })
    episApi.edit(id, epi).then(
      () => {
        commit(
          'notifications',
          { title: i18n.t('SuccessUpdateMaculino', { nombre: i18n.t('Epi') }), variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorUpdate', { nombre: i18n.t('Epi') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  clone({ commit }, { idepi, idlocation, quantity }) {
    commit('app/loading', true, { root: true })
    episApi.clone(idepi, idlocation, quantity).then(
      () => {
        commit(
          'notifications',
          { title: i18n.t('SuccessCloned', { nombre: i18n.t('Epi') }), variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        commit('app/loading', false, { root: true })
        location.reload()
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorCloned', { nombre: i18n.t('Epi') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    episApi.delete(id).then(
      () => {
        commit(
          'notifications',
          { title: i18n.t('SuccessDeleteMasculino', { nombre: i18n.t('Epi') }), variant: 'success' },
          { root: true },
        )
        location.reload()
      },
      (error) => {
        commit('app/loading', false, { root: true })
        if (error.response.data.error === 'This object is assigned to a task.') {
          commit('notifications', { title: i18n.t('ErrorDeleteInstallationEpiAssigned'), variant: 'danger' }, { root: true })
        } else {
          commit(
            'notifications',
            { title: i18n.t('ErrorDelete', { nombre: i18n.t('Epi') }), variant: 'danger' },
            { root: true },
          )
        }
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  create({ commit }, { epi }) {
    commit('app/loading', true, { root: true })
    episApi.create(epi).then(
      response => {
        commit(
          'notifications',
          { title: i18n.t('SuccessCreateMasculino', { nombre: i18n.t('Epis') }), variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        router.push({ name: 'epis' })
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorCreate', { nombre: i18n.t('Epis') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
}

const getters = {
  getEpi: state => state.epi,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getSelectStatus: state => state.selectStatus,
  getSelectEpis: state => state.selectEpis,
  getListSummaryState: state => state.summaryEpisClient,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
