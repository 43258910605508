import flatpickr from 'flatpickr'
import i18n from '@/libs/i18n'
import { config } from '../../shared/app.config'

if (i18n.locale !== 'en') {
  const request = require('flatpickr/dist/l10n/' + config.localesFlatPicker[i18n.locale] + '.js')
  const confLocale = request.default[config.localesFlatPicker[i18n.locale]]
  flatpickr.localize(confLocale) // default locale is now Russian
}

flatpickr.setDefaults(
  {
    dateFormat: 'd/m/Y',
  },
)
