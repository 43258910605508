import axios from 'axios'
import { config } from '../shared/app.config'

export default {
  status(type) {
    return axios.post(`${config.webApiBase}/status/list`, {
      type,
    })
  },
  types(type) {
    return axios.post(`${config.webApiBase}/types/list`, {
      type,
    })
  },
  roles() {
    return axios.get(`${config.webApiBase}/roles/list`)
  },
  dashboard_assets(client = '', category = '', search = '', status = '', vida_util = '', prox_revision = '', product = '', user = '', date_ini = '', date_fin = '', location = '', num_albaran = '') {
    return axios.post(`${config.webApiBase}/dashboard/assets`, {
      id_client: client,
      category_id: category,
      search,
      status,
      vida_util,
      prox_revision,
      product_id: product,
      user_id: user,
      date_ini,
      date_fin,
      location_id: location,
      num_albaran,
    })
  },
  dashboard_epis(client = '', category = '', search = '', status = '', vida_util = '', prox_revision = '', product = '', user = '', date_ini = '', date_fin = '', location = '', num_albaran = '') {
    return axios.post(`${config.webApiBase}/dashboard/epis`, {
      id_client: client,
      category_id: category,
      search,
      status,
      vida_util,
      prox_revision,
      product_id: product,
      user_id: user,
      date_ini,
      date_fin,
      location_id: location,
      num_albaran,
    })
  },
  dashboard_installations(client = '', category = '', search = '', status = '', vida_util = '', prox_revision = '', product = '', is_epi = '', user = '', date_ini = '', date_fin = '', location = '', num_albaran = '') {
    return axios.post(`${config.webApiBase}/dashboard/installations`, {
      id_client: client,
      category_id: category,
      search,
      status,
      vida_util,
      prox_revision,
      product_id: product,
      is_epi,
      user_id: user,
      date_ini,
      date_fin,
      location_id: location,
      num_albaran,
    })
  },
  dashboard_comun({client = '', user_id = ''}) {
    return axios.post(`${config.webApiBase}/dashboard/comun`, {
      id_client: client,
      user_id
    })
  },
}
