import { config } from '@/shared/app.config'

const state = {
  languages: [],
  currentLanguage: '',
}

const mutations = {
  recoverLanguages(state) {
    const languages = JSON.parse(localStorage.getItem('languages'))
    if (!languages) {
      this.dispatch('languages/getLanguages')
    } else {
      state.languages = languages
    }
  },
  recoverCurrentLanguage(state) {
    const currentLanguage = JSON.parse(localStorage.getItem('currentLanguage'))
    if (!currentLanguage) {
      state.currentLanguage = config.defaultLocale
      this.commit('languages/setCurrentLanguage', state.currentLanguage)
    } else {
      state.currentLanguage = currentLanguage
    }
  },
  setCurrentLanguage(state, payload) {
    state.currentLanguage = payload
    localStorage.setItem(
      'currentLanguage',
      JSON.stringify(state.currentLanguage),
    )
  },
}

const actions = {
  getLanguages({ commit }) {
    localStorage.setItem('languages', JSON.stringify(config.locales))
    commit('recoverLanguages')
  },
}

const getters = {
  getLanguages: state => state.languages,
  getCurrentLanguage: state => {
    if (state.currentLanguage) return state.currentLanguage
    return config.defaultLocale
  },
  getLocaleDatePicker: state => {
    if (state.currentLanguage) return config.localesDatePicker[state.currentLanguage]
    return config.localesDatePicker[config.defaultLocale]
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
