import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/tags'

export default {
  create(tag) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, tag)
  },
  list(idClient = '', idSerie = '', idProduct = '', idStatus = '', search = '', perPage, page, date_ini = '', date_fin = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      client_id: idClient,
      serie_id: idSerie,
      product_id: idProduct,
      status_tag_id: idStatus,
      search,
      per_page: perPage,
      page,
      date_ini,
      date_fin,
    })
  },
  export(idClient = '', idSerie = '', idProduct = '', idStatus = '', search = '', perPage, page, date_ini = '', date_fin = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/export`, {
      client_id: idClient,
      serie_id: idSerie,
      product_id: idProduct,
      status_tag_id: idStatus,
      search,
      per_page: perPage,
      page,
      date_ini,
      date_fin,
    })
  },
  getTag(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
}
