import axios from "axios";
import { config } from "../shared/app.config";

const RESOURCE_NAME = "/externaleventslogs";

export default {
  create(event) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, event)
  },
  edit(id, event) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, event);
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`);
  },
  getListAsset(asset_id, page, per_page, search, type_object) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${asset_id}`, {
      page,
      per_page,
      search,
      type_object,
    })
  },
  downloadZip(id) {
    const response = axios({
      url: `${config.webApiBase + RESOURCE_NAME}/zip/${id}`,
      data: {},
      method: 'POST',
      responseType: 'blob',
    })
    
    return response
  }
}