import router from "@/router";
import generalApi from "@/api/general-api";
import i18n from "@/libs/i18n";
import installationsApi from "../../api/installations-api";

const state = {
  installation: {},
  items: [],
  selectStatus: [],
  selectInstallations: [],
  totalItems: 0,
  summaryInstallationsClient: [],
};

const mutations = {
  setInstallation(state, val) {
    state.installation = val;
  },
  setItems(state, val) {
    state.items = val;
  },
  setTotalItems(state, val) {
    state.totalItems = val;
  },
  setSelectStatus(state, val) {
    state.selectStatus = val;
  },
  setSelectInstallations(state, val) {
    state.selectInstallations = val;
  },
  setSummaryInstallationsClient(state, val) {
    state.summaryInstallationsClient = val;
  },
};

const actions = {
  async selectStatus({ commit }) {
    await generalApi.status("installation").then(
        (response) => {
          commit("setSelectStatus", response.data);
        },
        () => {
          commit(
              "notifications",
              {
                title: i18n.t("ErrorListado", { nombre: i18n.t("Estados") }),
                variant: "danger",
              },
              { root: true }
          );
          commit("setShowNotifications", true, { root: true });
        }
    );
  },
  async getInstallation({ commit }, id) {
    commit("app/loading", true, { root: true });
    await installationsApi.getInstallation(id).then(
        (response) => {
          if (response.data.data.archives) {
            if (response.data.data.archives.review_installation) {
              response.data.data.archives.review =
                  response.data.data.archives.review_installation;
            }
            if (response.data.data.archives.review_epi) {
              response.data.data.archives.review =
                  response.data.data.archives.review_epi;
            }
            if (response.data.data.archives.delivery_no_epi) {
              response.data.data.archives.delivery =
                  response.data.data.archives.delivery_no_epi;
            }
            if (response.data.data.archives.delivery_epi) {
              response.data.data.archives.delivery =
                  response.data.data.archives.delivery_epi;
            }
          }
          commit("setInstallation", response.data.data);
          commit("app/loading", false, { root: true });
        },
        () => {
          commit("app/loading", false, { root: true });
          commit(
              "notifications",
              {
                title: i18n.t("ErrorGet", { nombre: i18n.t("installation") }),
                variant: "danger",
              },
              { root: true }
          );
          commit("setShowNotifications", true, { root: true });
        }
    );
  },
  getListInstallations(
      { commit },
      {
        client = "",
        page,
        per_page,
        search = "",
        category_id = "",
        province_id = "",
        status = "",
        prox_revision = "",
        product = "",
        date_ini,
        date_fin,
        location_id,
        subclient_id = "",
      }
  ) {
    commit("app/loading", true, { root: true });
    installationsApi
        .getList(
            client,
            page,
            per_page,
            search,
            category_id,
            status,
            prox_revision,
            product,
            date_ini,
            date_fin,
            location_id,
            province_id,
            subclient_id
        )
        .then(
            (response) => {
              response.data.data.forEach((element) => {
                if (element.archives) {
                  if (element.archives.review_installation) {
                    element.archives.review = element.archives.review_installation;
                  }
                  if (element.archives.review_epi) {
                    element.archives.review = element.archives.review_epi;
                  }
                  if (element.archives.delivery_no_epi) {
                    element.archives.delivery = element.archives.delivery_no_epi;
                  }
                  if (element.archives.delivery_epi) {
                    element.archives.delivery = element.archives.delivery_epi;
                  }
                }
              });
              commit("setItems", response.data.data);
              commit("setTotalItems", response.data.meta.total[1]);
              commit("app/loading", false, { root: true });
            },
            () => {
              commit("app/loading", false, { root: true });
              commit(
                  "notifications",
                  {
                    title: i18n.t("ErrorListado", { nombre: i18n.t("installations") }),
                    variant: "danger",
                  },
                  { root: true }
              );
              commit("setShowNotifications", true, { root: true });
            }
        );
  },
  getListSummary({ commit }, { product_id }) {
    commit("app/loading", true, { root: true });
    installationsApi.getListSummary(product_id).then(
        (response) => {
          commit("setSummaryInstallationsClient", response.data);
          commit("app/loading", false, { root: true });
        },
        () => {
          commit("app/loading", false, { root: true });
          commit(
              "notifications",
              {
                title: i18n.t("ErrorCarga", { nombre: i18n.t("Dashboard") }),
                variant: "danger",
              },
              { root: true }
          );
          commit("setShowNotifications", true, { root: true });
        }
    );
  },
  async getSelectInstallations({ commit }, { client = "" }) {
    await installationsApi.getList(client, 1, 9999999, "").then(
        (response) => {
          commit("setSelectInstallations", response.data.data);
        },
        () => {
          commit(
              "notifications",
              {
                title: i18n.t("ErrorListado", { nombre: i18n.t("Equipos") }),
                variant: "danger",
              },
              { root: true }
          );
          commit("setShowNotifications", true, { root: true });
        }
    );
  },
  edit({ commit }, { id, installation }) {
    commit("app/loading", true, { root: true });
    installationsApi.edit(id, installation).then(
        () => {
          commit(
              "notifications",
              {
                title: i18n.t("SuccessUpdate", {
                  nombre: i18n.t("installation"),
                }),
                variant: "success",
              },
              { root: true }
          );
          commit("setShowNotifications", true, { root: true });
          commit("app/loading", false, { root: true });
        },
        () => {
          commit("app/loading", false, { root: true });
          commit(
              "notifications",
              {
                title: i18n.t("ErrorUpdate", { nombre: i18n.t("installation") }),
                variant: "danger",
              },
              { root: true }
          );
          commit("setShowNotifications", true, { root: true });
        }
    );
  },
  clone({ commit }, { idinstallation, idlocation, quantity }) {
    commit("app/loading", true, { root: true });
    installationsApi.clone(idinstallation, idlocation, quantity).then(
        () => {
          commit(
              "notifications",
              {
                title: i18n.t("SuccessCloned", { nombre: i18n.t("installation") }),
                variant: "success",
              },
              { root: true }
          );
          commit("setShowNotifications", true, { root: true });
          commit("app/loading", false, { root: true });
          location.reload();
        },
        () => {
          commit("app/loading", false, { root: true });
          commit(
              "notifications",
              {
                title: i18n.t("ErrorCloned", { nombre: i18n.t("installation") }),
                variant: "danger",
              },
              { root: true }
          );
          commit("setShowNotifications", true, { root: true });
        }
    );
  },
  create({ commit }, { installation }) {
    commit("app/loading", true, { root: true });
    installationsApi.create(installation).then(
        (response) => {
          commit(
              "notifications",
              {
                title: i18n.t("SuccessCreate", { nombre: i18n.t("installation") }),
                variant: "success",
              },
              { root: true }
          );
          commit("setShowNotifications", true, { root: true });
          router.push({ name: "installations" });
          commit("app/loading", false, { root: true });
        },
        () => {
          commit("app/loading", false, { root: true });
          commit(
              "notifications",
              {
                title: i18n.t("ErrorCreate", { nombre: i18n.t("installation") }),
                variant: "danger",
              },
              { root: true }
          );
          commit("setShowNotifications", true, { root: true });
        }
    );
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    installationsApi.delete(id)
        .then(
            () => {
              commit('notifications', { title: i18n.t('SuccessDelete', { nombre: i18n.t('installation') }), variant: 'success' }, { root: true })
              location.reload()
            },
            (error) => {
              commit('app/loading', false, { root: true })
              if (error.response.data.error === 'This object is assigned to a task.') {
                commit('notifications', { title: i18n.t('ErrorDeleteInstallationTaskAssigned'), variant: 'danger' }, { root: true })
              } else {
                commit('notifications', { title: i18n.t('ErrorDelete', { nombre: i18n.t('installation') }), variant: 'danger' }, { root: true })
              }
              commit('setShowNotifications', true, { root: true })
            },
        )
  },
};

const getters = {
  getInstallation: (state) => state.installation,
  getItems: (state) => state.items,
  getTotalItems: (state) => state.totalItems,
  getSelectStatus: (state) => state.selectStatus,
  getSelectInstallations: (state) => state.selectInstallations,
  getListSummaryState: (state) => state.summaryInstallationsClient,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
