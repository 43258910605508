import router from '@/router'
import generalApi from '@/api/general-api'
import i18n from '@/libs/i18n'
import assetsApi from '../../api/assets-api'

const state = {
  asset: {},
  items: [],
  selectStatus: [],
  selectAssets: [],
  totalItems: 0,
  summaryAssetsClient: [],
}

const mutations = {
  setAsset(state, val) {
    state.asset = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setSelectStatus(state, val) {
    state.selectStatus = val
  },
  setSelectAssets(state, val) {
    state.selectAssets = val
  },
  setSummaryAssetsClient(state, val) {
    state.summaryAssetsClient = val
  },
}

const actions = {
  async selectStatus({ commit }) {
    await generalApi.status('asset').then(
      response => {
        commit('setSelectStatus', response.data)
      },
      () => {
        commit(
          'notifications',
          { title: i18n.t('ErrorListado', { nombre: i18n.t('Estados') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  async getAsset({ commit }, id) {
    commit('app/loading', true, { root: true })
    await assetsApi.getAsset(id).then(
      response => {
        if (response.data.data.archives) {
          if (response.data.data.archives.review_asset) {
            response.data.data.archives.review = response.data.data.archives.review_asset
          }
          if (response.data.data.archives.review_epi) {
            response.data.data.archives.review = response.data.data.archives.review_epi
          }
          if (response.data.data.archives.delivery_no_epi) {
            response.data.data.archives.delivery = response.data.data.archives.delivery_no_epi
          }
          if (response.data.data.archives.delivery_epi) {
            response.data.data.archives.delivery = response.data.data.archives.delivery_epi
          }
        }
        commit('setAsset', response.data.data)
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorGet', { nombre: i18n.t('Equipo') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  getListAssets(
    { commit },
    {
      client = '',
      page,
      per_page,
      search = '',
      category_id = '',
      status = '',
      vida_util = '',
      prox_revision = '',
      product = '',
      user = '',
      date_ini,
      date_fin,
      location_id,
      num_albaran,
      assets_id,
      realod
    },
  ) {
    if(realod) {
      commit('app/loading', true, { root: true })
    }
    return new Promise((resolve, reject) => {
      assetsApi
        .getList(
          client,
          page,
          per_page,
          search,
          category_id,
          status,
          vida_util,
          prox_revision,
          product,
          user,
          date_ini,
          date_fin,
          location_id,
          num_albaran,
          assets_id
        )
        .then(
          response => {
            response.data.data.forEach(element => {
              if (element.archives) {
                if (element.archives.review_asset) {
                  element.archives.review = element.archives.review_asset
                }
                if (element.archives.review_epi) {
                  element.archives.review = element.archives.review_epi
                }
                if (element.archives.delivery_no_epi) {
                  element.archives.delivery = element.archives.delivery_no_epi
                }
                if (element.archives.delivery_epi) {
                  element.archives.delivery = element.archives.delivery_epi
                }
              }
            })
            commit('setItems', response.data.data)
            commit('setTotalItems', response.data.meta.total[1])
            commit('app/loading', false, { root: true })
            resolve(response)
          },
          (err) => {
            commit('app/loading', false, { root: true })
            commit(
              'notifications',
              {
                title: i18n.t('ErrorListado', { nombre: i18n.t('Equipos') }),
                variant: 'danger',
              },
              { root: true },
            )
            commit('setShowNotifications', true, { root: true })
            reject(err)
          },
        )
    }) 
  },
  getListSummary({ commit }, { product_id }) {
    commit('app/loading', true, { root: true })
    assetsApi.getListSummary(product_id).then(
      response => {
        commit('setSummaryAssetsClient', response.data)
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          {
            title: i18n.t('ErrorCarga', { nombre: i18n.t('Dashboard') }),
            variant: 'danger',
          },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  async getSelectAssets({ commit }, { client = '' }) {
    await assetsApi.getList(client, 1, 9999999, '').then(
      response => {
        commit('setSelectAssets', response.data.data)
      },
      () => {
        commit(
          'notifications',
          {
            title: i18n.t('ErrorListado', { nombre: i18n.t('Equipos') }),
            variant: 'danger',
          },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  edit({ commit }, { id, asset }) {
    commit('app/loading', true, { root: true })
    assetsApi.edit(id, asset).then(
      () => {
        commit(
          'notifications',
          { title: i18n.t('SuccessUpdateMaculino', { nombre: i18n.t('Equipo') }), variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorUpdate', { nombre: i18n.t('Equipo') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  clone({ commit }, { idasset, idlocation, quantity }) {
    commit('app/loading', true, { root: true })
    assetsApi.clone(idasset, idlocation, quantity).then(
      () => {
        commit(
          'notifications',
          { title: i18n.t('SuccessCloned', { nombre: i18n.t('Equipo') }), variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        commit('app/loading', false, { root: true })
        location.reload()
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorCloned', { nombre: i18n.t('Equipo') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    assetsApi.delete(id).then(
      () => {
        commit(
          'notifications',
          { title: i18n.t('SuccessDeleteMasculino', { nombre: i18n.t('Equipo') }), variant: 'success' },
          { root: true },
        )
        location.reload()
      },
      (error) => {
        commit('app/loading', false, { root: true })
        if (error.response.data.error === 'This object is assigned to a task.') {
          commit('notifications', { title: i18n.t('ErrorDeleteInstallationAssetAssigned'), variant: 'danger' }, { root: true })
        } else {
          commit(
            'notifications',
            { title: i18n.t('ErrorDelete', { nombre: i18n.t('Equipo') }), variant: 'danger' },
            { root: true },
          )
      }
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  async create({ commit }, { asset, reload }) {
    if (reload) { 
      commit('app/loading', true, { root: true })
    }
    return new Promise((resolve, reject) => {
      assetsApi.create(asset).then(
        (response) => {
          commit(
            'notifications',
            { title: i18n.t('SuccessCreateMasculino', { nombre: i18n.t('Equipos') }), variant: 'success' },
            { root: true },
          )
          if (reload) {
            router.push({ name: 'assets' })
            commit('app/loading', false, { root: true })
          }
          commit('setShowNotifications', true, { root: true })
          resolve(response)
        },
        (err) => {
          commit('app/loading', false, { root: true })
          commit(
            'notifications',
            { title: i18n.t('ErrorCreate', { nombre: i18n.t('Equipos') }), variant: 'danger' },
            { root: true },
          )
          commit('setShowNotifications', true, { root: true })
          reject(err)
        },
      )
    })
  },
}

const getters = {
  getAsset: state => state.asset,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getSelectStatus: state => state.selectStatus,
  getSelectAssets: state => state.selectAssets,
  getListSummaryState: state => state.summaryAssetsClient,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
