import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import notifications from './notifications'
import auth from './auth'
import languages from './languages'
import clients from './clients'
import subclients from "./subclients";
import companies from './companies'
import users from './users'
import products from './products'
import departments from './departments'
import projects from './projects'
import tasks from './tasks'
import events from './events'
import actions from './actions'
import forms from './forms'
import locations from './locations'
import assets from './assets'
import epis from './epis'
import sets from './sets'
import series from './series'
import tags from './tags'
import categories from './categories'
import installations from './installations'
import externalEvents from "./external-events"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    notifications,
    auth,
    languages,
    clients,
    companies,
    users,
    subclients,
    departments,
    products,
    projects,
    tasks,
    events,
    actions,
    forms,
    locations,
    assets,
    epis,
    installations,
    sets,
    series,
    tags,
    categories,
    externalEvents,
  },
  strict: process.env.DEV,
})
