import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/tagseries'

export default {
  create(serie) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, serie)
  },
  edit(id, serie) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, serie)
  },
  list(idClient = '', search = '', perPage, page) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      client_id: idClient,
      search,
      per_page: perPage,
      page,
    })
  },
  getSerie(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
}
