import tagsApi from '@/api/tags-api'
import router from '@/router'
import generalApi from '@/api/general-api'

const state = {
  tag: {},
  items: [],
  selectStatus: [],
  totalItems: 0,
}

const mutations = {
  setTag(state, val) {
    state.tag = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setSelectStatus(state, val) {
    state.selectStatus = val;
  },
}

const actions = {
    async getListTags(
    { commit },
    {
      client, serie, product, status, page, per_page, search = '', date_ini = '', date_fin = '',
    },
  ) {
    commit('app/loading', true, { root: true })
    await tagsApi.list(client, serie, product, status, search, per_page, page, date_ini, date_fin).then(
      response => {
        commit('setItems', response.data.data)
        commit('setTotalItems', response.data.meta.total[1])
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          {
            title: 'Error al obtener el listado de etiquetas.',
            variant: 'danger',
          },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  async export(
    { commit },
    {
      client, serie, product, status, page, per_page, search = '', date_ini = '', date_fin = '',
    },
  ) {
    commit('app/loading', true, { root: true })
    await tagsApi.export(client, serie, product, status, search, per_page, page, date_ini, date_fin).then(
      response => {
        console.log("hola")
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data)

        // create "a" HTML element with href to file & click
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', 'list.csv') //or any other extension
        document.body.appendChild(link)
        link.click()
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)

        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          {
            title: 'Error al descargar el listado de etiquetas.',
            variant: 'danger',
          },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  async getTag({ commit }, id) {
    commit('app/loading', true, { root: true })
    await tagsApi.getTag(id).then(
      response => {
        commit('setTag', response.data)
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: 'Error al obtener la etiqueta.', variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  create({ commit }, { tag }) {
    commit('app/loading', true, { root: true })
    tagsApi.create(tag).then(
      () => {
        commit(
          'notifications',
          { title: 'Etiquetas creadas con éxito.', variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        router.push({ name: 'tags' })
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: 'Error al crear las etiquetas.', variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    tagsApi.delete(id).then(
      () => {
        commit(
          'notifications',
          { title: 'Etiqueta eliminada con éxito.', variant: 'success' },
          { root: true },
        )
        location.reload()
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: 'Error al eliminar la etiqueta.', variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  async selectStatus({ commit }) {
    await generalApi.status('tag').then(
      response => {
        const status = []
        response.data.forEach(element => {
          if (
            element.alias !== 'blocked'
          ) {
            status.push(element)
          }
        })
        commit('setSelectStatus', status)
      },
      () => {
        commit(
          'notifications',
          { title: 'Error en la carga de estados.', variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
}

const getters = {
  getTag: state => state.tag,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getSelectStatus: state => state.selectStatus,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
