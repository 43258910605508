import axios from "axios";
import { config } from "../shared/app.config";

const RESOURCE_NAME = "/installations";

export default {
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  edit(id, installation) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, installation);
  },
  clone(idinstallation, idlocation, quantity) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/duplicate`, {
      installation_id: idinstallation,
      location_id: idlocation,
      quantity,
    });
  },
  create(installation) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, installation);
  },
  getInstallation(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`);
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`);
  },
  getList(
      client = "",
      page,
      per_page,
      search,
      category_id = "",
      status = "",
      prox_revision = "",
      product = "",
      date_ini,
      date_fin,
      location_id,
      province_id = "",
      subclient_id = ""
  ) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      id_client: client,
      page,
      per_page,
      search,
      category_id,
      status,
      prox_revision,
      province_id,
      product_id: product,
      date_ini,
      date_fin,
      location_id,
      subclient_id,
    });
  },
  getListSummary(product_id) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/summary`, {
      product_id,
    });
  },
};
