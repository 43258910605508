import axios from "axios";
import { config } from "../shared/app.config";

const RESOURCE_NAME = "/subclients";

export default {
  edit(id, user) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, user);
  },
  create(user) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, user);
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`);
  },
  getSubClient(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`);
  },
  list(client = "", page, per_page, search) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      client_id: client,
      page,
      per_page,
      search,
    });
  },
  resetPassword(id, oldPassword, newPassword) {
    return axios.put(
      `${config.webApiBase + RESOURCE_NAME}/${id}/password-change`,
      {
        oldPassword,
        newPassword,
      }
    );
  },
};

/* Generado el 04 de marzo 2024
Esta api se crea para el listado de los clientes (empresas) de forma
que las empresas sean únicas y seleccionables.

https://api-fototrace.pinchaaqui.net/api/documentation -> subclients
*/
