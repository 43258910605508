import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/assets'

export default {
  edit(id, asset) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, asset)
  },
  clone(idasset, idlocation, quantity) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/duplicate`, {
      asset_id: idasset,
      location_id: idlocation,
      quantity,
    })
  },
  create(asset) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, asset)
  },
  getAsset(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  getList(
    client = '',
    page,
    per_page,
    search,
    category_id = "",
    status = "",
    vida_util = "",
    prox_revision = "",
    product = "",
    user = "",
    date_ini,
    date_fin,
    location_id,
    num_albaran,
    assets_id
  ) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      id_client: client,
      page,
      per_page,
      search,
      category_id,
      status,
      vida_util,
      prox_revision,
      product_id: product,
      user_id: user,
      date_ini,
      date_fin,
      location_id,
      num_albaran,
      assets_id
    })
  },
  getListSummary(product_id) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/summary`, {
      product_id,
    })
  },
}
