import departmentsApi from '@/api/departments-api'
import router from '@/router'
import i18n from '@/libs/i18n'

const state = {
  department: {},
  items: [],
  totalItems: 0,
  selectDepartments: [],
  showModalCreateDepartment: false,
}

const mutations = {
  setDepartment(state, val) {
    state.department = val
  },
  setShowModalCreateDepartment(state, val) {
    state.showModalCreateDepartment = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setSelectDepartments(state, val) {
    state.selectDepartments = val
  },
}

const actions = {
  async selectDepartments({ commit }, { clients }) {
    let array = []
    await clients.forEach(async (element, key) => {
      await departmentsApi.list(element.id, '', 99999999, 1).then(
        response => {
          if (key !== 0) {
            array = state.selectDepartments
          }
          array.push({ name: element.name, data: response.data.data })
        },
        () => {
          commit(
            'notifications',
            {
              title: i18n.t('ErrorListado', { nombre: i18n.t('Departamentos') }),
              variant: 'danger',
            },
            { root: true },
          )
          commit('setShowNotifications', true, { root: true })
        },
      )
    })
    commit('setSelectDepartments', array)
  },
  async getListDepartments(
    { commit },
    {
      client, page, per_page, search = '',
    },
  ) {
    commit('app/loading', true, { root: true })
    await departmentsApi.list(client, search, per_page, page).then(
      response => {
        commit('setItems', response.data.data)
        commit('setTotalItems', response.data.meta.total[1])
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          {
            title: i18n.t('ErrorListado', { nombre: i18n.t('Departamentos') }),
            variant: 'danger',
          },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  async getDepartment({ commit }, id) {
    commit('app/loading', true, { root: true })
    await departmentsApi.getDepartment(id).then(
      response => {
        commit('setDepartment', response.data)
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorGet', { nombre: i18n.t('Departamento') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  edit({ commit }, { id, department }) {
    commit('app/loading', true, { root: true })
    departmentsApi.edit(id, department).then(
      () => {
        commit(
          'notifications',
          { title: i18n.t('SuccessUpdateMaculino', { nombre: i18n.t('Departamento') }), variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorUpdate', { nombre: i18n.t('Departamento') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  create({ commit }, { department }) {
    commit('app/loading', true, { root: true })
    departmentsApi.create(department).then(
      () => {
        commit(
          'notifications',
          { title: i18n.t('SuccessCreate', { nombre: i18n.t('Departamento') }), variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        router.push({ name: 'departments' })
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorCreate', { nombre: i18n.t('Departamento') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    return departmentsApi.delete(id).then(
      () => {
        commit(
          'notifications',
          { title: i18n.t('SuccessDelete', { nombre: i18n.t('Departamento') }), variant: 'success' },
          { root: true },
        )
      },
      error => {
        let errorMessage = i18n.t('ErrorDelete', { nombre: i18n.t('Departamento') })
        if (error.response.status === 400 && error.response.data.users) {
          errorMessage = i18n.t('ErrorDepartmentHasUsers')
        }
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: errorMessage, variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
}

const getters = {
  getDepartment: state => state.department,
  getSelectDepartments: state => state.selectDepartments,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getShowModalCreateDepartment: state => state.showModalCreateDepartment,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
